<template>
  <b-card>
    <b-row>
      <b-col cols="4">
        <b-form-group
          label="Client"
          label-for="manufac-client"
        >
          <b-form-select
            v-model="data.user_id"
            name="manufac-type"
            :options="userOptions"
            text-field="fullname"
            value-field="id"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Test"
          label-for="manufac-test"
        >
          <b-form-textarea
            v-model="data.test"
            name="manufac-test"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BFormGroup, BRow, BCol, BCard, BFormSelect, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BFormSelect,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    userOptions() {
      const list = this.$store.state.user.usersList
      if (list) {
        list.forEach(element => {
          // eslint-disable-next-line no-param-reassign
          element.fullname = `${element.enterprise} ${element.username}`
        })
      }
      return list
    },
  },
}
</script>
