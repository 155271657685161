<template>
  <b-card>
    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Mesure du 5 Volt"
          label-for="manufac-volt5Measure"
        >
          <b-form-input
            v-model="data.volt5Measure"
            name="manufac-volt5Measure"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="N° Bluetooth"
          label-for="manufac-bluetooth"
        >
          <b-form-input
            v-model="data.bluetoothNumber"
            name="manufac-bluetooth"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
}
</script>
