<template>
  <b-card>
    <b-row
      class="d-flex justify-content-center"
    >
      <b-col
        cols="6"
        class="d-flex flex-column justify-content-center"
      >
        <b-form-checkbox
          v-model="data.extend_system"
          class="custom-control-primary"
        >
          Étendue du système d'exploitation
        </b-form-checkbox>
        <b-form-checkbox
          v-model="data.calibration_battery"
          class="custom-control-primary"
        >
          Calibration de la batterie
        </b-form-checkbox>
        <b-form-checkbox
          v-model="data.headValidation"
          class="custom-control-primary"
        >
          Validation de la tête
        </b-form-checkbox>
        <b-form-checkbox
          v-model="data.manufacturingValidation"
          class="custom-control-primary"
        >
          Validation du processus de fabrication
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <label>Fichier à joindre (Autorise PDF, word, excel, images)</label>
        <b-form-file
          v-model="filesUploadTemp"
          accept=".jpg, .png, .pdf, .xls, .xlsx, .doc, .docx"
          placeholder="Choisir un fichier..."
          drop-placeholder="Déplacer un fichier ici..."
          multiple
        />
        <div
          class="d-flex justify-content-center mt-75"
        >
          <b-button
            variant="outline-primary"
            @click="uploadFile(filesUploadTemp)"
          >
            Ajouter
          </b-button>
        </div>
        <div v-if="filesUploadList.length > 0">
          <ul
            v-for="file, index in filesUploadList"
            :key="index"
          >
            <p>{{ file.name }}</p>
          </ul>
        </div>
      </b-col>
      <b-col
        v-if="actualFiles && actualFiles.length > 0"
        cols="12"
      >
        <h4>
          Pièces jointes
        </h4>
        <div
          v-for="file, indexFile in actualFiles"
          :key="indexFile"
        >
          <p>
            {{ file.originalname }}
            <feather-icon
              v-if="file.originalname"
              icon="XIcon"
              size="18"
              @click="removeFile(file.id, indexFile)"
            />
          </p>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BButton,
  BFormFile, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormFile,
    BFormCheckbox,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    manufacturingFile: {
      type: FormData,
      default: () => {},
    },
    actualFiles: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      filesUploadTemp: [],
      filesUploadList: [],
    }
  },
  methods: {
    async uploadFile(filesUpload) {
      const data = new FormData()
      filesUpload.forEach(file => {
        this.filesUploadList.push(file)
      })
      await filesUpload.forEach(file => {
        data.append('file', file)
        data.append('title', file.name)
        data.append('name', file.name)
        data.append('extension', file.type)
        data.append('mimetype', file.type)
        data.append('original_name', file.name)
        data.append('manufacturingId', this.data.id)
      })
      return this.$emit('update:manufacturingFile', data)
    },
    removeFile(fileId, index) {
      this.$store.dispatch('file/deleteFile', fileId)
        .then(() => {
          this.actualFiles.splice(index, 1)
          return this.actualFiles
          // this.$router.go()
        })
    },
  },
}
</script>
