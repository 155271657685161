<template>
  <b-card>
    <b-row>
      <b-col cols="6">
        <b-form-group
          label="N° Accéléromètre X"
          label-for="manufac-accelXnumber"
        >
          <b-form-input
            v-model="data.accelXnumber"
            name="manufac-accelXnumber"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Sensibilité Accéléromètre X (mV/g)"
          label-for="manufac-accelXsens"
        >
          <b-form-input
            v-model="data.accelXsens"
            name="manufac-accelXsens"
          />
        </b-form-group>
      </b-col>
      <!-- <b-col cols="4">
        <b-form-group
          label="Rapport Accéléromètre X"
          label-for="manufac-accelXreport"
        >
          <b-form-input
            v-model="data.accelXreport"
            name="manufac-accelXreport"
          />
        </b-form-group>
      </b-col> -->
      <b-col cols="6">
        <b-form-group
          label="N° Accéléromètre Y"
          label-for="manufac-accelYnumber"
        >
          <b-form-input
            v-model="data.accelYnumber"
            name="manufac-accelYnumber"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Sensibilité Accéléromètre Y (mV/g)"
          label-for="manufac-accelYsens"
        >
          <b-form-input
            v-model="data.accelYsens"
            name="manufac-accelYsens"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="N° Accéléromètre Z"
          label-for="manufac-accelZnumber"
        >
          <b-form-input
            v-model="data.accelZnumber"
            name="manufac-accelZnumber"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Sensibilité Accéléromètre Z (mV/g)"
          label-for="manufac-accelZsens"
        >
          <b-form-input
            v-model="data.accelZsens"
            name="manufac-accelZsens"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
}
</script>
