<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Commande"
          label-for="manufac-order"
        >
          <b-form-textarea
            v-model="data.order"
            name="manufac-order"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Type de device"
          label-for="manufac-type"
        >
          <b-form-select
            v-model="data.device_typeId"
            name="manufac-type"
            :options="deviceTypeOptions"
            text-field="name"
            value-field="id"
            required
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="N° de serie du device"
          label-for="manufac-device_serialnumber"
        >
          <b-form-input
            v-model="data.device_serialnumber"
            name="manufac-device_serialnumber"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <hr>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group
          label="N° de serie de la tête associée"
          label-for="manufac-head_serialnumber"
        >
          <b-form-input
            v-model="data.head_serialnumber"
            name="manufac-head_serialnumber"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="N° de serie de la valise associée"
          label-for="manufac-suitcase_serialnumber"
        >
          <b-form-input
            v-model="data.suitcase_serialnumber"
            name="manufac-suitcase_serialnumber"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="N° du lugdoswing associé"
          label-for="manufac-lugdoswing_serialnumber"
        >
          <b-form-input
            v-model="data.lugdoswing_serialnumber"
            name="manufac-lugdoswing_serialnumber"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="N° de serie du bumper associé"
          label-for="manufac-bumper_serialnumber"
        >
          <b-form-input
            v-model="data.bumper_serialnumber"
            name="manufac-bumper_serialnumber"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BCard,
  BFormTextarea, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import ModalCreateSerialnumber from '../serialnumber/ModalCreateSerialnumber.vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BFormSelect,
    // ModalCreateSerialnumber,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      deviceTypeOptions: this.$store.state.devicetype.devicetypesList,
    }
  },
}
</script>
